import axios from "axios";
import { API_VERSION } from "./config";

const UserService = {};

UserService.get = async (id) => {
  const response = await axios.get(`${API_VERSION}users/${id}`);
  return response.data;
};

UserService.getHotels = async (id) => {
  const response = await axios.get(`${API_VERSION}users/${id}/hotels`);
  return response.data;
};

UserService.updateHotels = async (userId, hotelId) => {
  const { data } = await axios.put(`${API_VERSION}users/${userId}/hotels/${hotelId}`, {
    score: "0",
    coins: "0",
  });
  return data;
};

UserService.update = async (data, id) => {
  const response = await axios.put(`${API_VERSION}users/${id}`, data);
  return response.data;
};

UserService.getHotelHistory = async (user_id) => {
  const response = await axios.get(`${API_VERSION}users/${user_id}/hotels`);
  return response.data;
};

UserService.getThings = async (user_id) => {
  const { data } = await axios.get(`${API_VERSION}users/${user_id}/suitcase`);
  return data;
};

UserService.updateRewards = async (user_id, data) => {
  const response = await axios.put(`${API_VERSION}users/${user_id}/suitcase`, data);
  return response.data;
};

export default UserService;
