import React from "react";
import { FooterBarStyled } from "./styles";
import ImageHomeIndicator from './images/home-indicator.svg'

export function FooterBar() {
  return (
    <FooterBarStyled>
      <figure>
        <img src={ImageHomeIndicator} alt="..."/>
      </figure>
    </FooterBarStyled>
  )
} 