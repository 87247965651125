import create from "zustand";
import { devtools } from "zustand/middleware";
import { setItem, getItem } from "utils/localStorage";
import UserService from "services/UserService";
import HotelService from "services/HotelService";
import StrengthService from "services/StrengthService";
import SuitecaseService from "services/SuitecaseService";
import managersData from "Data/managers.json";

const defaultManager = {
  name: "Juan Stoessel",
  nickname: "Juancho",
  jobPosition: "Gerente General",
  avatar: "juancho.jpeg",
  imgPath: "/images/avatars/juancho.jpg",
};

const useStore = create(
  devtools((set, get) => ({
    auth: getItem("ca-auth"),
    user: {
      id: null,
      name: "",
      lastname: "",
      document: "",
      hotel: null,
      position: null,
      strength: null,
      gender: null,
      compromise: null,
      experience: 0,
      visitedHotels: [],
      rewards: [],
      date_start_game: null,
      date_end_game: null,
    },
    currentHotel: null,
    currentManager: {
      name: "Juan Stoessel",
      nickname: "Juancho",
      jobPosition: "Gerente General",
      avatar: "juancho.jpeg",
      imgPath: "/images/avatars/juancho.jpg",
    },
    indexLastManager: 0,
    hotels: [],
    rewards: [],
    strength: [],
    layout: {
      background: {
        imgPath: "backgrounds/cerro-siete-colores.png",
        gradient:
          "linear-gradient(168.5deg, rgba(16, 210, 255, 0.8) 34.17%, rgba(4, 255, 190, 0.8) 100%)",
      },
      showHeader: true,
      inChat: false,
      inBag: false,
      hideProgressBar: false,
      hideReturnBtn: false,
    },
    newRewards: 0,
    isLoading: false,
    error: null,
    showOnboarding: false,
    showHotelInfo: false,
    showBag: false,
    showFinalMessage: false,

    setAuth: (value) => {
      set((state) => ({ auth: value }));
    },
    setShowBag: (boolean) => {
      set((state) => ({ showBag: boolean, newRewards: 0 }));
    },
    setShowOnboarding: (bolean) => {
      set((state) => ({ showOnboarding: bolean }));
    },
    setShowHotelInfo: (bolean) => {
      set((state) => ({ showHotelInfo: bolean }));
    },
    setCurrentHotel: (hotel) => {
      let manager;
      if (hotel) {
        const managers = managersData["corporativas"];
        const managerToBeShowed = managers[get().indexLastManager];
        manager = managerToBeShowed;
        const indexLastManager =
          get().indexLastManager !== 9 ? get().indexLastManager + 1 : 0;
        setItem("ca-local-persisted", {
          currentHotel: hotel,
          currentManager: manager,
          indexLastManager: indexLastManager,
        }); // to persist in LS
        set((state) => ({
          currentHotel: hotel,
          currentManager: manager,
          indexLastManager: indexLastManager,
        }));
      } else {
        setItem("ca-local-persisted", {
          currentHotel: hotel,
          currentManager: defaultManager,
        }); // to persist in LS
        set((state) => ({ currentHotel: hotel, currentManager: defaultManager }));
      }
    },
    resetNewRewards: () => {
      setItem("ca-local-persisted", { newRewards: 0 }); // to persist in LS
      set((state) => ({ newRewards: 0 }));
    },
    setLayout: (value) => {
      set((state) => ({ layout: { ...state.layout, ...value } }));
    },
    setShowFinalMessage: (value) => {
      set((state) => ({ showFinalMessage: value }));
    },
    showChatHeader: () => {
      set((state) => ({
        layout: { ...state.layout, inChat: true, hideProgressBar: false },
      }));
    },
    hideChatHeader: () => {
      set((state) => ({ layout: { ...state.layout, inChat: false } }));
    },
    enterInBag: () => {
      set((state) => ({ layout: { ...state.layout, inBag: true } }));
    },
    OutOfBag: () => {
      set((state) => ({ layout: { ...state.layout, inBag: false } }));
    },
    getCurrentHotel: () => {
      const currentHotel = get().currentHotel;

      if (!currentHotel) {
        const currentHotelLS = getItem("ca-local-persisted")?.currentHotel;
        if (currentHotelLS) {
          set((state) => ({ currentHotel: currentHotelLS }));
          return currentHotelLS;
        }
      }

      return currentHotel;
    },
    setStore: async () => {
      try {
        const localStorageData = getItem("ca-local-persisted");
        const [user, visitedHotels, userRewards, hotels, strengths, rewards] =
          await Promise.all([
            UserService.get(get().auth.userId),
            UserService.getHotels(get().auth.userId),
            UserService.getThings(get().auth.userId),
            HotelService.getAll(),
            StrengthService.getAll(),
            SuitecaseService.getAll(),
          ]);
        set((state) => ({
          user: {
            id: user.data.id,
            name: user.data.name,
            lastname: user.data.lastname,
            email: user.data.email,
            document: user.data.document,
            hotel: user.data.hotel,
            position: user.data.position,
            strength: user.data.strength,
            experience: user.data.experience,
            compromise: user.data.compromise,
            gender: user.data.gender,
            visitedHotels: visitedHotels.data,
            rewards: userRewards.data.things,
            date_start_game: user.data.date_start_game,
            date_end_game: user.data.date_end_game,
          },
          hotels: hotels.data,
          strengths: strengths.data,
          rewards: rewards.data,
          newRewards: localStorageData?.newRewards || 0,
          currentHotel: localStorageData?.currentHotel || null,
          currentManager: localStorageData?.currentManager || null,
          indexLastManager: localStorageData?.indexLastManager || 0,
          showFinalMessage: visitedHotels.data.length === 10,
        }));
      } catch (error) {}
    },
    addVisitedHotel: async () => {
      set((state) => ({ isLoading: true }));
      try {
        const userId = get().user?.id;
        const hotelId = get().currentHotel?.id;
        const response = await UserService.updateHotels(userId, hotelId);
        const hotelAdded = response.data;

        setItem("ca-local-persisted", {
          currentHotel: null,
          currentManager: defaultManager,
        });
        set((state) => ({
          user: {
            ...state.user,
            visitedHotels: [...state.user.visitedHotels, hotelAdded],
          },
          currentHotel: null,
          currentManager: defaultManager,
          showFinalMessage: state.user.visitedHotels.length + 1 === 10,
        }));
      } catch (error) {
        console.log(error);
        set((state) => ({ error: error.message }));
      } finally {
        set((state) => ({ isLoading: false }));
      }
    },
    addReward: async (reward) => {
      set((state) => ({ isLoading: true }));
      try {
        const rewardsIds = get().user.rewards.map(({ id }) => ({ id }));
        const bodyObj = {
          name: "SuitCase",
          things: [...rewardsIds, { id: reward.id }],
        };
        await UserService.updateRewards(get().auth.userId, bodyObj);
        setItem("ca-local-persisted", { newRewards: get().newRewards + 1 }); // to persist in LS
        const existentReward = get().user.rewards.find((item) => item.id === reward.id);
        if (!existentReward) {
          set((state) => ({
            user: {
              ...state.user,
              rewards: [...state.user.rewards, reward],
            },
            newRewards: get().newRewards + 1,
          }));
        }
      } catch (error) {
        console.log(error);
      } finally {
        set((state) => ({ isLoading: false }));
      }
    },
    updateUser: async (dataObj) => {
      set((state) => ({ isLoading: true }));
      try {
        const userId = get().user.id;
        await UserService.update(dataObj, userId);
        set((state) => ({
          user: {
            ...state.user,
            ...dataObj,
          },
        }));
      } catch (error) {
        console.log(error);
      } finally {
        set((state) => ({ isLoading: false }));
      }
    },
  }))
);

export default useStore;
