import styled from 'styled-components';
import bgBag from 'assets/images/bag/bgBagInnerSmall.png';
import bgMenu from 'assets/images/bag/bgMenu.png';

export const BagTittle = styled.div`
	background-color: #4d2310;
	width: 50%;
	position: relative;
	text-align: center;
	padding: 0.5rem;
	border-radius: 15px 15px 0px 0px;
	font-size: 24px;
	color: #fff;
	font-weight: bold;
	font-style: italic;
`;

export const BagSeparator = styled.div`
	width: 100%;
	background-color: #4d2310;
	height: 2vh;
`;

export const BagContainer = styled.div`
	width: 100%;
	height: 100%;
	background-image: url(${bgBag});
	background-color: #4d2310;
	background-position: top;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
	padding: 6vh 0 15vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	gap: 2vh;
	::before {
		content: '';
		position: absolute;
		top: -8px;
		left: 0;
		width: 100%;
		height: 10px;
		background-color: #4d2310;
	}
`;

export const Menu = styled.div`
	width: 70%;
	height: 65px; //12vh
	min-height: 65px;
	max-height: 65px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: auto;
	/* margin: auto; */
	background: url(${bgMenu});
	background-position: bottom center;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	@media (min-width: 425px) {
		width: 90%;
	}
`;

export const Item = styled.div`
	display: flex;
	gap: 0.2rem;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: ${props => (props.isActive ? '#04FFBE' : '#FFFCFC')};
	font-size: clamp(0.6rem, 2vw, 2.5rem);
	font-weight: bold;
	background-color: ${props => (props.isActive ? 'linear-gradient(360deg, #04FFBE -0.01%, #EC0BD3 184.73%)' : 'none')};
	img {
		height: 2rem;
		width: fit-content;
	}
	@media (min-width: 450px) {
		font-size: clamp(0.6rem, 1.2vw, 2.5rem);
	}
`;
