import axios from "axios"
import { API_VERSION } from "./config";

const AuthServices = {};

AuthServices.login = async (data) => {
  const response = await axios.post(`${API_VERSION}login/secret-password`, data);
  return response.data;
}

AuthServices.verifityToken = async (user_id) => {  
  const response = await axios.get(`${API_VERSION}users/${user_id}`);
  return response;
}

export default AuthServices;