import { keyframes } from "styled-components"

export const OpacityAnimate = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`

export const RotateAnimate = keyframes`
  from {
    transform: rotate(0)
  }
  to {
    transform: rotate(360deg)
  }
`