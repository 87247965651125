import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Button } from "components/StyledComponents";
import ParchmentOpenImg from "assets/images/bag/parchment.png";
import useStore from "context/store";

const ContainerParchmentParaph = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  padding: 1vw;
  background: linear-gradient(180.36deg, #01feff 0.3%, #9d00ff 98.18%);
`;


const ParchmentWrapper = styled.div`
  background-image: url(${ParchmentOpenImg});
  width: 100%;
  height: 70vh;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
`;

const ParchmentParaph = styled.p`
  width: 80%;
  margin: 0 auto;
  padding: 1rem;
  text-align: center;
`;
const ParchmentFinal = ({ onHide }) => {
  const navigate = useNavigate();
  const { name, strength, compromise } = useStore((state) => state.user);
  const setShowFinalMessage = useStore((state) => state.setShowFinalMessage);

  const onContinueClick = () => {
    setShowFinalMessage(false);
    if (!compromise) {
      navigate("/data-commitment");
    } else {
      navigate("/thank_you");
    }
  };

  return (
    <ContainerParchmentParaph>
      <ParchmentWrapper>
        <ParchmentParaph>
          “Felicitaciones <b>{name?.toUpperCase() || ""}</b> por haber llegado hasta aquí.
          Quiero decirte que admiramos tu capacidad de{" "}
          <b>{strength?.name.toUpperCase() || ""}</b>. Tu y yo sabemos que al ponernos al
          servicio de los demás nos descubrimos a nosotros mismos y a nuestra felicidad.
          Te agradezco por acompañarnos con tu talento para lograr que el Perú supere
          todas las expectativas”
        </ParchmentParaph>
      </ParchmentWrapper>
        <Button.FullYellow onClick={onContinueClick}>Continuar</Button.FullYellow>
      {/* <ButtonsWrapper position="relative" width="100%">
      </ButtonsWrapper> */}
    </ContainerParchmentParaph>
  );
};

export default ParchmentFinal;
