/*External imports */
import React, { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
/*Internal imports */
import { PreloaderComponent } from "components/Preloader";

/*Dynamic components imports */
const DataWelcome = lazy(() => import("views/DataCollect/DataWelcome"));
const DataPersonal = lazy(() => import("views/DataCollect/DataPersonal"));
const DataHotel = lazy(() => import("views/DataCollect/DataHotel"));
const DataGender = lazy(() => import("views/DataCollect/DataGender"));
const DataStrenght = lazy(() => import("views/DataCollect/DataStrenght"));
const DataExperience = lazy(() => import("views/DataCollect/DataExperience"));
const WelcomeChat = lazy(() => import("views/WelcomeChat"));
const Home = lazy(() => import("views/home"));
const Hotel = lazy(() => import("views/Hotel"));
const Thanks = lazy(() => import("views/Hotel/Thanks"));
const Chat = lazy(() => import("views/Hotel/Chat"));
const Trivia = lazy(() => import("views/Hotel/Trivia"));
const Chest = lazy(() => import("views/Hotel/Chest"));
const Commitment = lazy(() => import("views/Commitment"));
const Bag = lazy(() => import("views/Bag"));
const MoreInfo = lazy(() => import("views/Hotel/MoreInfo"));
const WindowGain = lazy(() => import("views/Hotel/WindowGain"));
const ThankYou = lazy(() => import("views/thankYou"));
const Parchment = lazy(() => import("views/Hotel/ParchmentGain"));
const Layout = lazy(() => import("views/Layout"));

export default function ListRoutes() {
  return (
    <>
      <Suspense fallback={<PreloaderComponent />}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<DataWelcome />} />
            <Route path="data-personal" element={<DataPersonal />} />
            <Route path="data-hotel" element={<DataHotel />} />
            <Route path="data-gender" element={<DataGender />} />
            <Route path="data-strength" element={<DataStrenght />} />
            <Route path="data-experience" element={<DataExperience />} />
            <Route path="welcome-chat" element={<WelcomeChat />} />
            <Route path="home" element={<Home />} />
            <Route path="checkin" element={<Hotel />}>
              <Route path="chest" element={<Chest />} />
              <Route path="chat" element={<Chat />} />
              <Route path="trivia" element={<Trivia />} />
              <Route path="parchment-reward" element={<Parchment />} />
              <Route path="info" element={<MoreInfo />} />
              <Route path="window-reward" element={<WindowGain />} />
              <Route path="thanks" element={<Thanks />} />
            </Route>
            <Route path="bag" element={<Bag />} />
            <Route path="data-commitment" element={<Commitment />} />
            <Route path="thank_you" element={<ThankYou />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
      </Suspense>
    </>
  );
}
