/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { HeaderComponent } from "components/HeaderComponent";
import LogoLg from "assets/images/login/logo-lg.svg";
import ForCollaboraters from "assets/images/login/for-collaboraters.svg";
import ArrowRight from "assets/images/login/arrow-right.svg";
import { Container, Button, Image, Text, Input } from "components/StyledComponents";
import { FooterBar } from "components/FooterBar";
import useGetImage from "hooks/useGetImage";
import viewsGradientsData from "Data/viewsGradients.json";
import AuthServices from "services/authServices";
import useStore from "context/store";
import { setItem } from "utils/localStorage";
import ReactGA from "react-ga";
export default function Login() {
  const setAuth = useStore((state) => state.setAuth);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { image } = useGetImage("backgrounds/machu-picchu.png");
  const BACKGROUND = `${viewsGradientsData["login"]["login"]}, url(${image})`;

  const signInHandleSubmit = async (e) => {
    e.preventDefault();
    const formData = Object.fromEntries(new FormData(e.target));

    ReactGA.event({
      category: "login",
      action: "click",
      label: "Click Login",
      value: formData.email,
      // nonInteraction: false,
    });
    
    try {
      setIsLoading(true);
      const response = await AuthServices.login(formData);
      const authObj = {
        userId: response.user_id,
        token: response.access_token,
        type: response.type_token,
      };
      setItem("ca-auth", authObj);
      setIsLoading(false);
      setAuth(authObj);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setError(error);
    }
  };

  useEffect(() => {
    const location = window.location.pathname;
    ReactGA.pageview(location); // pageview event to GA
  }, []);

  return (
    <Fragment>
      <Container.View background={BACKGROUND}>
        <HeaderComponent
          btnBackBgColor="#33333333"
          except={["application-name", "check-in-link"]}
        />
        <Container.Main>
          <Container.Flex flexDirection="column" gap="40px" width="100%">
            <Container.Flex flexDirection="column">
              <Container.Figure>
                <Image.Default className="logo" src={LogoLg} alt="..." />
              </Container.Figure>
              <Container.Figure>
                <Image.Default className="text-logo" src={ForCollaboraters} alt="... " />
              </Container.Figure>
            </Container.Flex>
            <Container.Form onSubmit={signInHandleSubmit} padding="0">
              <Container.Block>
                <Text.Paragraph margin="auto" fontWeight="500" textAlign="center">
                  ¿Quieres conocer tu mensaje secreto?
                </Text.Paragraph>
              </Container.Block>
              <Input.Password
                type="password"
                className="form-control"
                placeholder="Ingresa aquí tu clave secreta"
                inputColor="#fff"
                placeholderColor="#fff"
                background="#FFFFFF4D"
                borderColor="#fff"
                name="secret_password"
                textAlign="center"
              />
              {error && <Text.Paragraph> Clave secreta incorrecta</Text.Paragraph>}
              <Button.Full type="submit" color="#000" fontWeight="500">
                {isLoading ? (
                  <span> Cargando ...</span>
                ) : (
                  <Fragment>
                    Empezar mi viaje
                    <Container.Figure>
                      <Image.Default src={ArrowRight} alt="..." />
                    </Container.Figure>
                  </Fragment>
                )}
              </Button.Full>
            </Container.Form>
          </Container.Flex>
        </Container.Main>
        <FooterBar />
      </Container.View>
    </Fragment>
  );
}
