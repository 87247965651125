import React from "react";
import { Header } from "./styles";
import ArrowLeft from "./images/arrow-left.svg";
import LogoSm from "./images/logo-sm.svg";
import { useNavigate } from "react-router-dom";

/**
 *
 * @params
 * String: btnBackBgColor
 * String: nameHotel
 * Array: except
 * @returns
 *
 */

export function HeaderComponent({ btnBackBgColor, except = [], nameHotel, backTo }) {
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (typeof backTo === "function") {
      backTo();
    } else if (typeof backTo === "string") {
      navigate(`${backTo}`);
    }
  };
  return (
    <Header btnBackBgColor={btnBackBgColor}>
      <nav>
        <div className="first-option">
          {backTo && (
            <button onClick={handleBackClick} className="btn-back">
              <img src={ArrowLeft} alt="..." />
            </button>
          )}

          {!except.includes("application-name") && (
            <div className="application-name">
              <img src={LogoSm} alt="..." />
            </div>
          )}
          {except.includes("application-name") && nameHotel && (
            <div className="application-name">{nameHotel}</div>
          )}
        </div>
        {!except.includes("check-in-link") && (
          <div className="option">
            <a href="#" className="check-in-link">
              CHECK IN
            </a>
          </div>
        )}
      </nav>
    </Header>
  );
}
