import React from "react";
import ReactDOM from "react-dom/client";
import GlobalStyled from "./styled";
import "utils/axios";
import App from "./App";
import "./index.css";

import { HashRouter } from "react-router-dom";
import ReactGA from 'react-ga';

const TRACKING_ID = "UA-238860066-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const rootElement = document.querySelector("#root");
const portalElement = document.querySelector("#portal");
const windowInnerHeight = window.innerHeight;
rootElement.style.height = `${windowInnerHeight}px`;
portalElement.style.height = `${windowInnerHeight}px`;
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <GlobalStyled />
    <HashRouter>
      <App className="app" />
    </HashRouter>
  </React.StrictMode>
);
