/* eslint-disable react-hooks/exhaustive-deps */
/*External imports*/
import React, { useEffect, useState } from "react";
/*Internal imports*/
import { Container } from "components/StyledComponents";
import { Button } from "components/StyledComponents";
import useStore from "context/store";
import { BagTittle, BagContainer } from "./styled";
import MenuContainer from "./MenuContainer";
import ItemContent from "./ItemContent";

const Bag = () => {
  const [categorySelected, setCategorySelected] = useState("windows");
  const setLayout = useStore((state) => state.setLayout);
  const setShowBag = useStore((state) => state.setShowBag);

  const handleBack = () => {
    setShowBag(false);
  };

  useEffect(() => {
    setLayout({
      inChat: false,
      inBag: true,
    });
  }, []);

  return (
    <>
      <Container.Bag background="#4d2310">
        <BagTittle>MALETA</BagTittle>
        <BagContainer>
          <MenuContainer
            setCategorySelected={setCategorySelected}
            categorySelected={categorySelected}
          />
          <ItemContent categorySelected={categorySelected} />
          <Button.FullYellow
            inlineSize="60%"
            text="Volver"
            margin="auto 0 0 0"
            padding="0.5rem 0"
            position="fixed"
            bottom="2vh"
            onClick={handleBack}
          />
        </BagContainer>
      </Container.Bag>
    </>
  );
};

export default Bag;
