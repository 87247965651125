import styled, { css } from "styled-components";
import IconCirclePlus from "assets/images/provide-information/circle-plus.png";
import { OpacityAnimate } from "animations";

export const ContentChest = styled.div`
  position: relative;
  text-align: center;
  & > :nth-child(1) {
    position: absolute;
    inset-block-start: -20%;
    margin: auto;
  }
`;

export const View = styled.section`
  background: ${(props) => props.background};
  background-blend-mode: ${(props) => props.backgroundBlendMode};
  background-position: ${(props) => props.backgroundPosition || "center"};
  background-size: ${(props) => props.backgroundSize || "cover"};
  background-repeat: ${(props) => props.backgroundRepeat || "no-repeat"};
  overflow: ${(props) => props.overflow || "hidden"};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12.8px;
  height: 100vh;
  max-width: 475px;
  inline-size: 100%;
  /* justify-content: space-between; */
  position: relative;
  margin: auto;
`;

export const Header = styled.header`
  align-items: center;
  background-color: #fff;
  background: rgba(15, 17, 32, 0.9);
  border-radius: 0px 0px 13px 13px;
  box-shadow: 0px 8px 15px rgba(15, 17, 32, 0.5);
  color: #fff;
  display: flex;
  gap: 10px;
  flex-direction: column;
  grid-column: 1 / span 12;
  inline-size: 100%;
  justify-content: space-evenly;
  padding: 10px 0 15px;
`;

export const Main = styled.main`
  align-items: center;
  display: flex;
  flex-direction: ${(props) => props.flexDirection || "column"};
  /* height: 80vh; */
  height: inherit;
  overflow-y: ${(props) => props.overflow || "hidden"};
  inline-size: 100%;
  padding: ${(props) => props.padding || "0 19px"};
  justify-content: ${(props) => props.justifyContent || "center"};
  overflow-x: ${(props) => props.overflowX || "hidden"};
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-end"};
  margin-bottom: ${(props) => props.marginBottom || "8vh"};
  padding: 8px 12.8px 0 12.8px;
  inline-size: ${(props) => props.inlineSize || "100vw"};
`;

export const Avatar = styled.div`
  border-radius: 50%;
  ${(props) => {
    if (props.size === "small") {
      return css`
        inline-size: 35px;
        block-size: 35px;
      `;
    } else if (props.size === "medium") {
      return css`
        inline-size: 45px;
        block-size: 45px;
      `;
    } else {
      return css`
        inline-size: 100%;
        block-size: 100%;
      `;
    }
  }}
  background: ${(props) => props.background};
  background-size: cover;
`;

export const User = styled.div`
  align-items: center;
  display: grid;
  gap: 16px;
  grid-template-columns: auto 3fr;
  inline-size: 100%;
  padding: 0 var(--general-padding);
`;
export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const FloatContent = styled.div`
  backdrop-filter: blur(8px);
  background: ${(props) => props.background};
  border-radius: ${(props) =>
    props.sender === "user" ? "15px 15px 0 15px" : "15px 15px 15px 0"};
  box-shadow: 0px 4px 15px rgba(44, 5, 81, 0.35);
  margin: 0;
  ${(props) => props.sender === "user" && "margin-left: auto"};
  padding: 1.5rem 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: fit-content;
  max-width: 90%;
`;

export const FloatContentCommit = styled(FloatContent)`
  background: linear-gradient(
    180deg,
    rgba(4, 255, 190, 0.2) 0%,
    rgba(126, 11, 236, 0.2) 100%
  );
`;

export const Wrapper = styled.div`
  block-size: auto;
  inline-size: 100%;
  overflow: ${(props) => props.overflow};
  padding: ${(props) => props.padding};
  text-align: ${(props) => props.textAlign || "start"};
  margin: ${(props) => props.margin};
`;

export const Block = styled.div`
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  inline-size: ${(props) => props.width || "100%"};
  block-size: ${(props) => props.height || "auto"};
  overflow: ${(props) => props.overflow};
  text-align: ${(props) => props.textAlign || "start"};
  background: ${(props) => props.background || "transparent"};
  border: ${(props) => props.border || "none"};
  box-shadow: ${(props) => props.boxShadow};
  border-radius: ${(props) => props.borderRadius};
  z-index: ${(props) => props.zIndex};
  line-height: ${(props) => props.lineHeight};
  /* margin-block-end: ${(props) => props.marginBlockEnd || "10px"}; */
  ${(props) =>
    props.display &&
    css`
      display: ${props.display};
      ${props.properties}
    `}
  ${(props) => {
    if (props.position) {
      switch (props.position) {
        case "absolute":
          return css`
            position: absolute;
            top: ${props.top};
            left: ${props.left};
            bottom: ${props.bottom};
            right: ${props.right};
          `;
        case "relative":
          return css`
            position: relative;
          `;
        default:
          return css``;
      }
    }
  }}
`;

export const ProvideInformationImage = styled.div`
  inline-size: ${(props) => props.width || "120px"};
  margin: ${(props) => props.margin || "auto"};
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  object-fit: cover;
  img {
    vertical-align: middle;
  }

  ${(props) =>
    props.unvisited &&
    css`
      &::before {
        content: "";
        background-color: #3e32628c;
        display: block;
        inline-size: 100%;
        block-size: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    `}

  &::after {
    content: "";
    display: block;
    background: transparent url(${IconCirclePlus}) no-repeat center center;
    inline-size: 100%;
    block-size: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
  }
`;

export const ModalContent = styled.div`
  /* max-block-size: 610px; */
  max-width: 450px;
  inline-size: 100%;
  background-color: #fff;
  border-radius: 15px;
  padding: 25px 10px 25px 15px;
  overflow: hidden;
  @media (max-height: 600px) {
    height: 80%;
  }
`;

export const GroupDropDown = styled.div`
  inline-size: 100%;
  max-block-size: 550px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-inline-end: 10px;
  &::-webkit-scrollbar {
    display: block;
    inline-size: 6px;
    scroll-margin: 20px;
    scroll-padding: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #7b61ff;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
`;

export const DropDownOption = styled.div`
  inline-size: 100%;
  background-color: #734ae633;
  border-radius: 10px;
  color: #3e3262;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 6px;
  font-weight: 500;
  background-color: ${(props) => props.active && `#04FFBE`};
  ${(props) =>
    props.visited &&
    css`
      opacity: 0.54;
    `};
`;

export const Figure = styled.figure`
  position: relative;
  block-size: ${(props) => props.height || "100%"};
  padding: ${(props) => props.padding};
  inline-size: auto;
  text-align: center;
  ${(props) => props.display && `display: ${props.display};`}
  ${(props) => props.alignItems && `align-items: ${props.alignItems};`}
  ${(props) => props.justifyContent && `justify-content: ${props.justifyContent};`}
  ${(props) => props.isDisabled && "filter: grayscale(1);"}
  ${(props) => props.isDisabled && "opacity: 0.4;"}
  img {
    vertical-align: middle;
  }
  border-radius: ${(props) => props.borderRadius};
  /* div:first-child{
    width: auto !important;
    height: auto !important;
  } */
  video {
    max-width: 50%;
  }
  svg {
    @media (max-height: 600px) {
      height: 45vh;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  inline-size: 100%;
  justify-content: center;
  padding: ${(props) => props.padding || "12.8px"};
`;

export const FormFeedback = styled.span`
  color: ${(props) => props.color || "red"};
  inline-size: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-align: start;
`;

export const OnboardingMessage = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: center;
  gap: 16px;
`;

export const OnboardingImage = styled.div`
  /* width: 60vw; */
  aspect-ratio: 1 / 1;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.numSlider === 7 ? 0 : 1)};
  img {
    max-inline-size: 100%;
    block-size: auto;
  }
`;

export const HotelPresentation = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* margin-bottom: 5vh; */
  height: 100%;
  justify-content: flex-end;
  padding: 2vh 4vh;
  background: ${(props) => (props.bg ? `url(${props.bg})` : "none")};
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: center;
  overflow: hidden;
`;

export const HotelName = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.1rem;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  flex-direction: ${(props) => props.flexDirection};
  gap: ${(props) => props.gap || "5px"};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  inline-size: ${(props) => props.width};
  position: ${(props) => props.position || "relative"};
`;

export const QuestionOptions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 15px;
  column-gap: 10px;
  margin-bottom: 15px;
`;

export const Slider = styled.div.attrs((props) => ({
  isVisible: props.isVisible ?? true,
}))`
  display: ${(props) => (props.isVisible ? "flex" : "none")};
  gap: 6vh;
  overflow-x: auto;
  flex-direction: column;
  align-items: center;
`;

export const Steps = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  justify-content: center;
  padding: 16px;
  margin-bottom: 4rem;
`;

export const Step = styled.div`
  width: ${(props) => (props.isActive ? "32px" : "9.6px")};
  height: 9.6px;
  border-radius: ${(props) => (props.isActive ? "50px" : "50%")};
  border: ${(props) => (props.isActive ? "none" : "2px solid #453525")};
  background: ${(props) => (props.isActive ? "#FFF" : "transparent")};
  .active {
    width: 32px;
    height: 9.6px;
    border-radius: 50px;
    border: none;
    background: transparent;
  }
`;

export const ChatMessage = styled.section`
  display: flex;
  align-items: flex-end;

  gap: 8px;
  padding-block-end: 15px;
  flex-direction: ${(props) => (props.isUser ? "row-reverse" : "row")};
  ${(props) =>
    props.animation &&
    css`
      animation: ${OpacityAnimate} 500ms linear 0s 1;
    `}
`;

export const Reward = styled.div`
  width: 80%;
  height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  img {
    max-inline-size: 100%;
  }
`;

export const Bag = styled.div`
  /* display: grid;
	grid-template-columns: 1fr;
	grid-auto-rows: 20px 1fr; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
`;

export const Chest = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100%; */
  margin-top: 3rem;
  img {
    max-inline-size: 60%;
  }
`;

export const BagCount = styled.span`
  color: #fff;
  background-color: red;
  position: absolute;
  right: -50%;
  top: 3px;
  border-radius: 50%;
  inline-size: 15px;
  block-size: 15px;
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  pointer-events: none;
`;

export const Window = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  img {
    max-inline-size: 50%;
  }
`;
