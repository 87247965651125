import axios from "axios"
import { API_VERSION } from "./config";

const HotelService = {};

HotelService.getAll = async () => {
  const response = await axios.get(`${API_VERSION}hotels`);
  // response.data.data.pop(); 
  // response.data.data.pop(); 
  let final = response.data.data;
  return {data: final};
}

HotelService.get = async (id) => {
  const response = await axios.get(`${API_VERSION}hotels/${id}`);
  return response.data;
}

export default HotelService;