import React from "react";
import ReactDOM from "react-dom";
// import { Backdrop } from "./styles";
import styled from "styled-components";

const Container = styled.div`
  background: #8393f5;
  position: absolute;
  left: ${(props) => props.left || 0};
  right: ${(props) => props.right || 0};
  bottom: ${(props) => props.bottom || 0};
  top: ${(props) => props.top || 0};
  border-radius: ${(props) => props.borderRadius || 0};
  display: ${(props) => (props.show ? "block" : "none")};
  z-index: 999;
  height: inherit;
`;

export default function Portal({ children, ...props }) {
  return ReactDOM.createPortal(
    <Container {...props}>{children}</Container>,
    document.getElementById("portal")
  );
}
