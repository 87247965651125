import { useEffect, useState } from 'react';

/**
 * It returns an object with three properties: loading, error, and image
 * @param path - The path to the image you want to import.
 * @returns An object with three properties: loading, error, and image.
 */

const useGetImage = (path) => {
	
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [image, setImage] = useState(null);
	
	useEffect(() => {
		const fetchImage = async () => {
			try {
				const response = await import(`assets/images/${path}` );
				setImage(response.default);
			} catch (err) {
				setError(err);
			} finally {
				setIsLoading(false);
			}
		};
		fetchImage();
	}, [path]);

	return {
		isLoading,
		error,
		image,
	};
};

export default useGetImage;
