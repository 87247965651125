import React, { useCallback, useMemo, useState } from "react";
import { Menu, Item } from "./styled";

const MenuContainer = ({ categorySelected, setCategorySelected }) => {
  const [images, setImages] = useState({
    itemWindow: "",
    itemParchment: "",
    itemBenefit: "",
  });

  const loadImage = useCallback(
    async (imageName, category) => {
      const active = categorySelected === category ? "-active" : "";
      const image = await import(`assets/images/bag/${imageName}${active}.png`);
      setImages((prev) => ({ ...prev, [imageName]: image.default }));
    },
    [categorySelected]
  );

  useMemo(() => {
    loadImage("itemWindow", "windows");
    loadImage("itemParchment", "parchments");
    loadImage("itemBenefit", "benefits");
  }, [loadImage]);

  return (
    <Menu>
      <Item
        onClick={() => setCategorySelected("windows")}
        isActive={categorySelected === "windows"}
      >
        {images.itemWindow && <img src={images.itemWindow} alt="item-piedras" />}
        <span>VENTANAS</span>
      </Item>
      <Item
        onClick={() => setCategorySelected("parchments")}
        isActive={categorySelected === "parchments"}
      >
        <img src={images.itemParchment} alt="item-pergaminos" />
        <span>PERGAMINOS</span>
      </Item>
      <Item
        onClick={() => setCategorySelected("benefits")}
        isActive={categorySelected === "benefits"}
      >
        <img src={images.itemBenefit} alt="item-beneficios" />
        <span>BENEFICIOS</span>
      </Item>
    </Menu>
  );
};

export default MenuContainer;
