import axios from "axios";
import { getItem } from "utils/localStorage";
axios.defaults.baseURL = "https://apicheckincolaboradores.aplicacionescasaandina.com";
//axios.defaults.baseURL = "http://127.0.0.1:8000";
axios.defaults.withCredentials = true;
axios.defaults.headers["Content-Type"] = "application/json";
axios.defaults.headers["Accept"] = "application/json";

axios.interceptors.request.use(function (config) {
  const auth = getItem("ca-auth");
  if (auth) {
    config.headers["Authorization"] = `${auth.type} ${auth.token}`;
  }
  return config;
});
