import styled from 'styled-components'

export const Header = styled.header`
  inline-size: 100%;
  padding: 10px var(--general-padding);
  nav {
    inline-size: 100%;
    /* block-size: 45px; */
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
  .first-option {
    display: flex;
    gap: 20px;
    align-items: center;
  }
  .btn-back {
    background: ${ props => props.btnBackBgColor };
    border: none;
    inline-size: 35px;
    block-size: 35px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    & > img {
      block-size: 11px;
    }
  }
  .check-in-link {
    color: #fff;
    text-decoration: none;
    font-weight: 700;
  }

  .application-name {
    font-weight: 600;
    img {
      block-size: 13px;
      vertical-align: middle;
    }
  }
`